<template>
  <div class="pageWrapper">
    <div>
      <van-button
        v-if="isEditing"
        class="addRecordButton"
        icon="add-o"
        type="primary"
        color="#2BB7B3"
        plain
        @click="addInprocessSingleWorkRecord"
        >增加</van-button
      >
      <div class="singleProblem" v-for="item in currentProblems" :key="item.id">
        <div>
          <span>{{
            `${item.questionTitle}    ${
              item.dispose ? "【已处理】" : "【未处理】"
            }`
          }}</span>
        </div>

        <div class="operationButton" v-if="!item.dispose">
          <van-button
            plain
            type="primary"
            color="#2BB7B3"
            size="mini"
            @click="handleDeal(item)"
            >处理</van-button
          >
        </div>
      </div>
    </div>
    <div class="bottomButtonWrapper">
      <van-button
        v-if="isEditing"
        @click="handleBack"
        class="bottomButton"
        plain
        type="primary"
        size="small"
        style="color:#2bb7b3;font-size:.8rem;font-weight:500;border: 1px solid #2bb7b3;border-radius:5px"
        >上一步</van-button
      >

      <van-button
        v-if="isEditing"
        @click="handleForward"
        class="bottomButton"
        type="info"
        size="small"
        style="font-size:.8rem;font-weight:500;background-color:#2bb7b3;border: 1px solid #2bb7b3;border-radius:5px"
        >下一步</van-button
      >
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

// dealDuringSchedule,
import { getDuringSchedule, finishMeeting } from "@/api/meetingList";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      currentProblems: []
    };
  },
  //监听属性 类似于data概念
  computed: {
    currentMeeting() {
      return this.$store.state.currentMeeting.meeting;
    },
    isEditing() {
      return this.$store.state.currentMeeting.isEditing;
    }
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    handleDeal(item) {
      console.log(item);
      this.$store.commit("SetIsEditingProblem", true);
      this.$store.commit("SetCurrentProblem", item);
      this.$store.commit("SetNavBarTitle", "运维问题");
      this.$router.push("/addMeetingInPrecessProblem");
      // dealDuringSchedule({ duringId: item.id })
      //   .then(res => {
      //     if (res.code === 10000) {
      //       this.getRecord();
      //     } else {
      //       this.$notify({ type: "warning", message: res.message });
      //     }
      //   })
      //   .catch(error => {
      //     this.$notify({ type: "warning", message: error.message });
      //   });
    },
    addInprocessSingleWorkRecord() {
      this.$store.commit("SetNavBarTitle", "增加运维问题");
      this.$router.push("/addMeetingInPrecessProblem");
      this.$store.commit("SetIsEditingProblem", false);
    },
    handleBack() {
      console.log("handleBack");
      this.$store.commit("SetNavBarTitle", "会前工作");
      this.$router.push("/meetingPrepare");
    },
    handleForward() {
      console.log("handleForward");
      finishMeeting({ workorderId: this.currentMeeting.workorderId })
        .then(res => {
          if (res.code === 10000) {
            this.$store.commit("SetNavBarTitle", "会后工作");
            this.$router.push("/afterMeetingProblem");
          } else {
            this.$notify({ type: "warning", message: res.message });
          }
        })
        .catch(error => {
          this.$notify({ type: "warning", message: error.message });
        });
    },
    getRecord() {
      getDuringSchedule({ workorderId: this.currentMeeting.workorderId })
        .then(res => {
          if (res.code === 10000) {
            console.log(res.data);
            this.currentProblems = res.data;
          } else {
            // this.$notify({ type: "warning", message: res.message });
          }
        })
        .catch(error => {
          this.$notify({ type: "warning", message: error.message });
        });
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getRecord();
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("SetNavBarTitle", to.meta.name);
    next();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {} //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style>
/* @import url(); 引入公共css类*/
.addRecordButton {
  width: 100%;
}
.addRecordButton .van-button__content .van-button__icon {
  font-size: 1.2rem;
}
.addRecordButton .van-button__content .van-button__text {
  font-size: 1rem;
}
.singleProblem {
  display: flex;
  background-color: #fff;
  margin: 10px 0;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
}
</style>
